/**
 * Adjusts the position of a tooltip or popover to ensure it remains visible within the modal's boundaries.
 * This function supports adjustments for "top" and "bottom" positions.
 *
 * @function adjustContextPosition
 * @param {VueComponent} element - The Vue component instance triggering the tooltip, providing access to its DOM element.
 * @param {string} defaultPosition - The preferred initial position for the tooltip or popover ("top", "bottom").
 * @param {number} tooltipHeight - The height of the tooltip or popover in pixels.
 * @param {VueComponent} modalComponent - The Vue component instance representing the modal.
 * @returns {string} - The new position of the tooltip or popover after adjustment.
 */
export const adjustContextPosition = (element, defaultPosition, tooltipHeight = 80, modalComponent) => {
    // Check if element and modalComponent are provided
    if (!element || !modalComponent) {
        console.error('Element or modalComponent is missing');
        return;
    }

    // Access the DOM elements via $el
    const elementRect = element[0].$el ? element[0].$el.getBoundingClientRect() : element[0].getBoundingClientRect();
    const modalRect = modalComponent.$el ? modalComponent.$el.getBoundingClientRect() : modalComponent.getBoundingClientRect();

    // Adjust the position if the tooltip would go out of the modal bounds
    let adjustedPosition = defaultPosition;

    if (elementRect.bottom + tooltipHeight > modalRect.bottom) {
        adjustedPosition = 'top';
    } else {
        adjustedPosition = 'bottom';
    }

    return adjustedPosition;
};
