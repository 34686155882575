import i18next from 'i18next';
import I18NextVue from 'i18next-vue';
import LanguageDetector from 'i18next-browser-languagedetector';


i18next
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: true,
        fallbackLng: 'en',
        resources: {
            //English
            en: {
                translation: {
                    // here we will place our translations...
                    all: 'All',
                    products: 'Products',
                    activities: 'Activities',
                    wpi: 'WPI',
                    department: 'Department',
                    safetyDataSheet: 'Safety Data Sheet (SDS)',
                    productBasedSafetySheet: 'Product based Safety Sheet',
                    wpiFile: 'Work Place Instruction (WPI)',
                    departmentLocation: 'Department location',
                    search: 'Search',
                    searchForProductsAndActivities: 'Search for products or activities',
                    searchForProductsActivitiesOrWPIs: 'Search for products, activities or WPIs',
                    activityBasedSafetySheet: 'Activity based Safety Sheet',
                    pageIsNotAvailable: 'This page isn\'t available',
                    invalidQrCodeOrUrl: 'The QR Code or URL link is invalid. Please try again or contact your IT administrator for assistance.',
                    noResults: 'No results found',
                    tryAdjustingSearch: 'Try adjusting your search to find what you\'re looking for.',
                    welcomeMessage: "Welcome to iChemistry Open Access!",
                    aboutApp: "With Open Access you get immediate access to information about your chemical products, so you can handle them safely.",
                    searchDesc: "Find your chemicals and corresponding handling instructions directly, using the search function.",
                    productsDesc: "Access the Safety Data Sheets for your chemicals.",
                    activitiesDesc: "Access the handling instructions for your chemicals that have been evaluated for potential risks.",
                    contactMessage: "Should you have any questions, please contact",
                    email: "email",
                    at: "at",
                    continue: 'Continue',
                    missingSDS: 'SDS not found',
                    infoSDS: "The requested SDS does not exist. Please try again or contact your IT administrator for assistance.",
                    seeAllProducts: "See all products",
                }
            },
            //Swedish
            sv: {
                translation: {
                    all: 'Alla',
                    products: 'Produkter',
                    activities: 'Aktiviteter',
                    department: 'Avdelning',
                    safetyDataSheet: 'Säkerhetsdatablad (SDB)',
                    productBasedSafetySheet: 'Produkt-baserat Skyddsblad',
                    departmentLocation: 'Avdelningsplats',
                    search: 'Sök',
                    searchForProductsAndActivities: 'Sök för produkter eller aktiviteter',
                    searchForProductsActivitiesOrWPIs: 'Sök efter produkter, aktiviteter eller WPI:er',
                    activityBasedSafetySheet: 'Aktivitets-baserat Skyddsblad',
                    pageIsNotAvailable: 'Sidan ej tillgänglig',
                    invalidQrCodeOrUrl: 'QR-koden eller URL-länken är ogiltig. Var god försök igen eller kontakta din IT-administratör för hjälp.',
                    noResults: 'Inga reslutat funna',
                    tryAdjustingSearch: 'Försök justera din sökning för att hitta det du letar efter.',
                    welcomeMessage: "Välkommen till iChemistry Open Access!",
                    aboutApp: "Med Open Access får du omedelbar tillgång till information om dina kemiska produkter, så att du kan hantera dem säkert.",
                    searchDesc: "Hitta dina kemikalier och motsvarande hanteringsinstruktioner direkt med hjälp av sökfunktionen.",
                    productsDesc: "Få tillgång till säkerhetsdatablad för dina kemikalier.",
                    activitiesDesc: "Få tillgång till hanteringsinstruktioner för dina kemikalier som har utvärderats för potentiella risker.",
                    contactMessage: "Om du har frågor, vänligen kontakta",
                    email: "e-post",
                    at: "på",
                    continue: 'Fortsätt',
                    missingSDS: 'SDS hittades inte',
                    infoSDS: "Det begärda säkerhetsdatabladet finns inte. Försök igen eller kontakta din IT-administratör för hjälp.",
                    seeAllProducts: "Se alla produkter",
                }
            },
            //Danish
            da: {
                translation: {
                    all: 'Alle',
                    products: 'Produkter',
                    activities: 'Arbejdsproces',
                    department: 'Afdeling',
                    safetyDataSheet: 'Sikkerhedsdatablad (SDS)',
                    productBasedSafetySheet: 'Produktbaseret sikkerhedsinstruktion',
                    departmentLocation: 'Afdelingsplacering',
                    search: 'Søg',
                    searchForProductsAndActivities: 'Søg efter produkter eller aktiviteter',
                    activityBasedSafetySheet: 'Procesbaseret sikkerhedsinstruktion',
                    pageIsNotAvailable: 'Denne side er ikke tilgængelig',
                    invalidQrCodeOrUrl: 'QR-koden eller URL-linket er ugyldigt. Prøv igen, eller kontakt din IT-administrator for at få hjælp.',
                    noResults: 'Ingen resultater fundet',
                    tryAdjustingSearch: 'Prøv at justere din søgning for at finde det, du leder efter.',
                    welcomeMessage: "Velkommen til iChemistry Open Access!",
                    aboutApp: "Med Open Access får du øjeblikkelig adgang til oplysninger om dine kemiske produkter, så du kan håndtere dem sikkert.",
                    searchDesc: "Find dine kemikalier og tilhørende sikkerhedsinstruktion direkte ved hjælp af søgefunktionen.",
                    productsDesc: "Få adgang til sikkerhedsdatabladene for dine kemikalier.",
                    activitiesDesc: "Få adgang til sikkerhedsinstruktion for de kemikalier, der er blevet vurderet for potentielle risici.",
                    contactMessage: "Hvis du har spørgsmål, bedes du kontakte",
                    email: "e-mail",
                    continue: "Fortsæt",
                    at: "hos",
                    missingSDS: 'SDS ikke fundet',
                    infoSDS: "Det anmodede SDS eksisterer ikke. Prøv venligst igen, eller kontakt din it-administrator for at få hjælp.",
                    seeAllProducts: "Se alle produkter",
                }
            },
            //Finnish
            fi: {
                translation: {
                    all: 'Kaikki',
                    products: 'Tuotteet',
                    activities: 'Aktiviteetit',
                    department: 'Osasto',
                    safetyDataSheet: 'Käyttöturvallisuustiedote (KTT)',
                    productBasedSafetySheet: 'Tuoteperusteinen turvallisuusohje',
                    departmentLocation: 'Osaston sijainti',
                    search: 'Hae',
                    searchForProductsAndActivities: 'Hae tuotteita tai toimintoja',
                    activityBasedSafetySheet: 'Toimintoperusteinen turvallisuusohje',
                    pageIsNotAvailable: 'Tämä sivu ei ole käytettävissä',
                    invalidQrCodeOrUrl: 'QR-kood või URL-i link on kehtetu. Proovige uuesti või võtke abi saamiseks ühendust oma IT-administraatoriga.',
                    noResults: 'Tulemeid ei leitud',
                    tryAdjustingSearch: 'Otsitava tulemuse leidmiseks proovige oma otsingut kohandada.',
                    welcomeMessage: "Tervetuloa iChemistry Open Accessiin!",
                    aboutApp: "Open Access antaa välittömän pääsyn kemikaaleja koskeviin tietoihin, jotta voit käsitellä niitä turvallisesti.",
                    searchDesc: "Löydä kemikaalisi ja vastaavat käsittelyohjeet suoraan hakutoiminnon avulla.",
                    productsDesc: "Tutustu kemikaalien käyttöturvallisuustiedotteisiin.",
                    activitiesDesc: "Tutustu mahdollisten riskien suhteen arvioitujen kemikaalien käsittelyohjeisiin.",
                    contactMessage: "Jos sinulla on kysyttävää, ota yhteyttä osoitteeseen",
                    email: "sähköposti",
                    continue: "Jatka",
                    at: "asiakaalle",
                    missingSDS: 'SDS:ää ei löydy',
                    infoSDS: "Pyydettyä käyttöturvallisuustiedotetta ei ole olemassa. Yritä uudelleen tai ota yhteyttä IT-järjestelmänvalvojaan saadaksesi apua.",
                    seeAllProducts: "Katso kaikki tuotteet",
                }
            },
            //Norwegian
            no: {
                translation: {
                    all: 'Alle',
                    products: 'Produkter',
                    activities: 'Aktiviteter',
                    department: 'Avdeling',
                    safetyDataSheet: 'Sikkerhetsdatablad (SDS)',
                    productBasedSafetySheet: 'Produktbasert verneblad',
                    departmentLocation: 'Avdelingsplassering',
                    search: 'Søk',
                    searchForProductsAndActivities: 'Søk etter produkter eller aktiviteter',
                    activityBasedSafetySheet: 'Aktivitetsbasert verneblad',
                    pageIsNotAvailable: 'Siden er ikke tilgjengelig',
                    invalidQrCodeOrUrl: 'QR-koden eller URL-lenken er ugyldig. Prøv igjen eller kontakt din IT-administrator for å få hjelp.',
                    noResults: 'Ingen resultater funnet',
                    tryAdjustingSearch: 'Prøv å justere søket for å finne det du leter etter.',
                    welcomeMessage: "Velkommen til iChemistry Open Access!",
                    aboutApp: "Med Open Access får du umiddelbar tilgang til informasjon om dine kjemiske produkter, slik at du kan håndtere dem på en sikker måte.",
                    searchDesc: "Finn dine kjemikalier og tilhørende håndteringsinstruksjoner direkte ved hjelp av søkefunksjonen.",
                    productsDesc: "Få tilgang til sikkerhetsdatabladene for kjemikaliene dine.",
                    activitiesDesc: "Få tilgang til håndteringsinstruksjonene for dine kjemikalier som er evaluert for potensielle risikoer.",
                    contactMessage: "Hvis du har spørsmål, kan du kontakte",
                    email: "e-post",
                    continue: "Fortsett",
                    at: "hos",
                    missingSDS: 'SDS ikke funnet',
                    infoSDS: "Den forespurte SDS-en eksisterer ikke. Prøv igjen eller kontakt IT-administratoren din for å få hjelp.",
                    seeAllProducts: "Se alle produkter",
                },
            },
            //German
            de: {
                translation: {
                    all: 'Alle',
                    products: 'Produkte',
                    activities: 'Tätigkeiten',
                    wpi: 'BA',
                    department: 'Abteilung',
                    safetyDataSheet: 'Sicherheitsdatenblatt (SDB)',
                    productBasedSafetySheet: 'Produktgebundenes Sicherheitsblatt',
                    wpiFile: 'Betriebsanweisung (BA)',
                    departmentLocation: 'Ort der Abteilung',
                    search: 'Suchen',
                    searchForProductsAndActivities: 'Produkte oder Aktivitäten suchen',
                    activityBasedSafetySheet: 'Tätigkeitsgebundenes Sicherheitsblatt',
                    pageIsNotAvailable: 'Diese Seite ist nicht verfügbar',
                    invalidQrCodeOrUrl: 'Der QR-Code bzw. URL-Link ist ungültig. Bitte versuchen Sie es erneut oder wenden Sie sich an Ihren IT-Administrator.',
                    noResults: 'Keine Ergebnisse gefunden',
                    tryAdjustingSearch: 'Versuchen Sie, Ihre Suche anzupassen, um das zu finden, wonach Sie suchen.',
                    welcomeMessage: "Willkommen bei iChemistry Open Access! ",
                    aboutApp: "Mit Open Access erhalten Sie sofortigen Zugriff auf Informationen über Ihre chemischen Produkte, um diese sicher handhaben zu können.",
                    searchDesc: "Finden Sie Ihre Chemikalien und die entsprechenden Handhabungshinweise direkt über die Suchfunktion.",
                    productsDesc: "Greifen Sie auf die Sicherheitsdatenblätter für Ihre Chemikalien zu.",
                    activitiesDesc: "Greifen Sie auf die Handhabungshinweise für Ihre Chemikalien zu, die auf potenzielle Risiken geprüft wurden.",
                    contactMessage: "Bei Fragen wenden Sie sich bitte an",
                    email: "E-Mail",
                    at: "bei",
                    continue: 'Weiter',
                    missingSDS: 'SDB nicht gefunden',
                    infoSDS: 'Das angeforderte SDB existiert nicht. Bitte versuchen Sie es erneut oder wenden Sie sich an Ihren IT-Administrator.',
                    seeAllProducts: "Alle Produkte anzeigen",
                }
            },
            //Bulgarian
            bg: {
                translation: {
                    all: 'Всички',
                    products: 'Продукти',
                    activities: 'Дейности',
                    department: 'Отдел',
                    safetyDataSheet: 'Информационен лист за безопасност (ИЛБ)',
                    productBasedSafetySheet: 'Лист за безопасност на база продукти',
                    departmentLocation: 'Местоположение на отдела',
                    search: 'Търсене',
                    searchForProductsAndActivities: 'Търсене за продукти или дейности',
                    activityBasedSafetySheet: 'Лист за безопасност на база дейност',
                    pageIsNotAvailable: 'Тази страница не е налична',
                    invalidQrCodeOrUrl: 'QR кодът или URL връзката са невалидни. Опитайте отново или се свържете с вашия ИТ администратор за съдействие.',
                    noResults: 'Не са намерени резултати',
                    tryAdjustingSearch: 'Опитайте се да коригирате търсенето, за да намерите това, което търсите.',
                    welcomeMessage: "Добре дошли в iChemistry Open Access!",
                    aboutApp: "С Open Access получавате незабавен достъп до информация за вашите химически продукти, за да можете да работите с тях безопасно.",
                    searchDesc: "Намерете директно вашите химикали и съответните инструкции за работа, като използвате функцията за търсене.",
                    productsDesc: "Осъществете достъп до информационните листове за безопасност за вашите химикали.",
                    activitiesDesc: "Осъществете достъп до инструкциите за работа с вашите химикали, които са били оценени за потенциални рискове.",
                    contactMessage: "Ако имате въпроси, се свържете със",
                    email: "имейл",
                    continue: "Продължаване",
                    at: "в",
                    missingSDS: 'ИЛБ не е намерен',
                    infoSDS: 'Заявеният ИЛБ не съществува. Опитайте отново или се свържете с вашия ИТ администратор за съдействие.',
                    seeAllProducts: "Виж всички продукти",
                },
            },
            //Chinese
            zn: {
                translation: {
                    all: '所有',
                    products: '产品',
                    activities: '活动',
                    department: '部门',
                    safetyDataSheet: '安全数据表 (SDS)',
                    productBasedSafetySheet: '基于产品的安全数据表',
                    departmentLocation: '部门所在地',
                    search: '搜索',
                    searchForProductsAndActivities: '搜索产品或活动',
                    activityBasedSafetySheet: '基于活动的安全数据表',
                    pageIsNotAvailable: '此页面不可用',
                    invalidQrCodeOrUrl: '二维码或 URL 链接无效。请重试或联系您的 IT 管理员寻求帮助。',
                    noResults: '未找到结果',
                    tryAdjustingSearch: '请尝试调整您的搜索条件，找到所需内容。',
                    welcomeMessage: "欢迎使用 iChemistry Open Access！",
                    aboutApp: "通过 Open Access，您可以立即获取有关化学产品的信息，以便安全处理。",
                    searchDesc: "使用搜索功能，直接查找您的化学品和相应的处理说明。",
                    productsDesc: "获取化学品的《安全数据表》。",
                    activitiesDesc: "获取已评估潜在风险的化学品的处理说明。",
                    contactMessage: "如有任何疑问，请联系",
                    email: "电子邮件",
                    continue: "继续",
                    at: "在",
                    missingSDS: '未找到 SDS',
                    infoSDS: '请求的 SDS 不存在。请重试或联系您的 IT 管理员寻求帮助。',
                    seeAllProducts: "查看所有产品",
                },
            },
            //Czech
            cs: {
                translation: {
                    all: 'Všichni',
                    products: 'Produkty',
                    activities: 'Činnosti',
                    department: 'Oddělení',
                    safetyDataSheet: 'Bezpečnostní list (BL)',
                    productBasedSafetySheet: 'Bezpečnostní list podle produktu',
                    departmentLocation: 'Umístění oddělení',
                    search: 'Hledat',
                    searchForProductsAndActivities: 'Vyhledávání produktů nebo činností',
                    activityBasedSafetySheet: 'Bezpečnostní list podle činnosti',
                    pageIsNotAvailable: 'Tato stránka není dostupná',
                    invalidQrCodeOrUrl: 'QR kód nebo odkaz na URL je neplatný. Opakujte akci nebo požádejte o pomoc správce IT.',
                    noResults: 'Nebyly nalezeny žádné výsledky',
                    tryAdjustingSearch: 'Zkuste upravit vyhledávání, abyste našli to, co hledáte.',
                    welcomeMessage: "Vítejte v systému iChemistry Open Access!",
                    aboutApp: "Díky Open Access získáte okamžitý přístup k informacím o vašich chemických produktech, abyste s nimi mohli bezpečně zacházet.",
                    searchDesc: "Pomocí funkce vyhledávání najdete přímo své chemikálie a příslušné pokyny k manipulaci.",
                    productsDesc: "Získáte přístup k bezpečnostním listům vašich chemikálií.",
                    activitiesDesc: "Získáte přístup k pokynům pro manipulaci s chemikáliemi, které byly posouzeny z hlediska potenciálních rizik.",
                    contactMessage: "Máte-li jakékoli dotazy, kontaktujte nás pomocí e-mailu",
                    email: "e-mail",
                    continue: "Pokračovat",
                    at: "v",
                    missingSDS: 'Bezpečnostní list nebyl nalezen',
                    infoSDS: 'Požadovaný bezpečnostní list neexistuje. Opakujte akci nebo požádejte o pomoc správce IT.',
                    seeAllProducts: "Zobrazit všechny produkty",
                },
            },
            //Dutch
            nl: {
                translation: {
                    all: 'Alle',
                    products: 'Producten',
                    activities: 'Activiteiten',
                    department: 'Afdeling',
                    safetyDataSheet: 'Veiligheidsinformatieblad',
                    productBasedSafetySheet: 'Productspecifiek veiligheidsblad',
                    departmentLocation: 'Afdelingslocatie',
                    search: 'Zoeken',
                    searchForProductsAndActivities: 'Zoeken naar producten of activiteiten',
                    activityBasedSafetySheet: 'Activiteitspecifiek veiligheidsblad',
                    pageIsNotAvailable: 'Deze pagina is niet beschikbaar',
                    invalidQrCodeOrUrl: 'De QR-code of URL-link is ongeldig. Probeer het opnieuw of neem voor hulp contact op met uw IT-beheerder.',
                    noResults: 'Geen resultaten gevonden',
                    tryAdjustingSearch: 'Pas uw zoekopdracht aan om te vinden wat u zoekt.',
                    welcomeMessage: "Welkom bij iChemistry Open Access!",
                    aboutApp: "Met Open Access hebt u onmiddellijk toegang tot informatie over uw chemische producten, zodat u er veilig mee kunt werken.",
                    searchDesc: "Vind met de zoekfunctie uw chemicaliën en de bijbehorende instructies voor hoe u met de chemicaliën moet werken.",
                    productsDesc: "Raadpleeg de veiligheidsinformatiebladen voor uw chemicaliën.",
                    activitiesDesc: "Raadpleeg de instructies voor hoe u moet werken met de chemicaliën die zijn beoordeeld op mogelijke risico's.",
                    contactMessage: "Neem bij vragen contact op met",
                    email: "e-mail",
                    continue: "Doorgaan",
                    at: "bij",
                    missingSDS: 'VIB niet gevonden',
                    infoSDS: 'Het gevraagde VIB bestaat niet. Probeer het opnieuw of neem voor hulp contact op met uw IT-beheerder.',
                    seeAllProducts: "Bekijk alle producten",
                },
            },
            //Estonian
            et: {
                translation: {
                    all: 'Kõik',
                    products: 'Tooted',
                    activities: 'Tegevused',
                    department: 'Osakond',
                    safetyDataSheet: 'Ohutuskaart (SDS)',
                    productBasedSafetySheet: 'Tootepõhine ohutuskaart',
                    departmentLocation: 'Osakonna asukoht',
                    search: 'Otsi',
                    searchForProductsAndActivities: 'Toodete või tegevuste otsimine',
                    activityBasedSafetySheet: 'Tegevuspõhine ohutuskaart',
                    pageIsNotAvailable: 'See leht pole saadaval',
                    invalidQrCodeOrUrl: 'QR-kood või URL-i link on kehtetu. Proovige uuesti või võtke abi saamiseks ühendust oma IT-administraatoriga.',
                    noResults: 'Tulemeid ei leitud',
                    tryAdjustingSearch: 'Otsitava tulemuse leidmiseks proovige oma otsingut kohandada.',
                    welcomeMessage: "Tere tulemast iChemistry Open Accessi!",
                    aboutApp: "Open Access annab teile kohese juurdepääsu teabele keemiatoodete kohta, et saaksite neid turvaliselt käidelda.",
                    searchDesc: "Leidke kemikaalid ja neile vastavad käitlemisjuhised otse otsingufunktsiooni abil.",
                    productsDesc: "Juurdepääs kemikaalide ohutuskaartidele.",
                    activitiesDesc: "Juurdepääs võimalike ohtude suhtes hinnatud kemikaalide käitlemisjuhistele.",
                    contactMessage: "Küsimuste korral võtke ühendust aadressil",
                    email: "meiliaadress",
                    continue: "Jätkake",
                    at: "lehel",
                    missingSDS: 'Ohutuskaarti ei leitud',
                    infoSDS: 'Soovitud ohutuskaarti ei ole olemas. Proovige uuesti või võtke abi saamiseks ühendust IT-administraatoriga.',
                    seeAllProducts: "Vaata kõiki tooteid",
                },
            },
            //French
            fr: {
                translation: {
                    all: 'Tous',
                    products: 'Produits',
                    activities: 'Activités',
                    department: 'Division',
                    safetyDataSheet: 'Fiche de données de sécurité (FDS)',
                    productBasedSafetySheet: 'Fiche de sécurité fondée sur le produit',
                    departmentLocation: 'Position de la division',
                    search: 'Rechercher',
                    searchForProductsAndActivities: 'Rechercher des produits ou des activités',
                    activityBasedSafetySheet: 'Fiche de sécurité fondée sur l’activité',
                    pageIsNotAvailable: 'Page non disponible',
                    invalidQrCodeOrUrl: 'Le code QR ou le lien URL est invalide. Veuillez réessayer ou contacter votre administrateur système pour obtenir de l\'aide.',
                    noResults: 'Aucun résultat',
                    tryAdjustingSearch: 'Veuillez ajuster votre recherche pour trouver ce que vous recherchez.',
                    welcomeMessage: "Bienvenue sur iChemistry Open Access !",
                    aboutApp: "8Avec Open Access, vous bénéficiez d’un accès immédiat aux informations relatives à vos produits chimiques, afin de pouvoir les manipuler en toute sécurité.",
                    searchDesc: "Trouvez vos produits chimiques et les instructions de manipulation correspondantes directement à l’aide de la fonction de recherche.",
                    productsDesc: "Accédez aux fiches de données de sécurité de vos produits chimiques.",
                    activitiesDesc: "Accédez aux instructions de manipulation de vos produits chimiques ayant fait l’objet d’une évaluation des risques potentiels.",
                    contactMessage: "Pour toute question, veuillez contacter",
                    email: "e-mail",
                    continue: "Continuer",
                    at: "à",
                    missingSDS: 'FDS introuvable',
                    infoSDS: 'La FDS demandée n’existe pas. Veuillez réessayer ou contacter votre administrateur système pour obtenir de l\'aide.',
                    seeAllProducts: "Voir tous les produits",
                }
            },
            //Hungarian
            hu: {
                translation: {
                    all: 'Mind',
                    products: 'Termékek',
                    activities: 'Tevékenységek',
                    department: 'Részleg',
                    safetyDataSheet: 'Biztonsági adatlap (SDS)',
                    productBasedSafetySheet: 'Termékalapú biztonsági adatlap',
                    departmentLocation: 'Részleg helye',
                    search: 'Keresés',
                    searchForProductsAndActivities: 'Termékek vagy tevékenységek keresése',
                    activityBasedSafetySheet: 'Tevékenységalapú biztonsági adatlap',
                    pageIsNotAvailable: 'Ez az oldal nem érhető el',
                    invalidQrCodeOrUrl: 'A QR-kód vagy az URL-hivatkozás érvénytelen. Próbálja újra, vagy forduljon segítségért a rendszergazdához.',
                    noResults: 'Nincs találat',
                    tryAdjustingSearch: 'Próbálja módosítani a keresést, hogy megtalálja, amit keres.',
                    welcomeMessage: "Üdvözli az iChemistry Open Access!",
                    aboutApp: "Az Open Access azonnali hozzáférést biztosít a vegyi termékeivel kapcsolatos információkhoz, így biztonságosan kezelheti azokat.",
                    searchDesc: "A keresés funkcióval közvetlenül megtalálhatja vegyi anyagait és azok kezelési utasításait.",
                    productsDesc: "Hozzáférhet a vegyi anyagok biztonsági adatlapjához.",
                    activitiesDesc: "Hozzáférhet a potenciális kockázatok szempontjából értékelt vegyi anyagai kezelési utasításaihoz.",
                    contactMessage: "Kérdés esetén forduljon hozzánk",
                    email: "e-mail",
                    continue: "Folytatás",
                    at: "ügyfélnél:",
                    missingSDS: 'Az SDS nem található',
                    infoSDS: 'Az igényelt SDS nem létezik. Próbálja újra, vagy forduljon segítségért a rendszergazdához.',
                    seeAllProducts: "Összes termék megtekintése",
                }
            },
            //Italian
            it: {
                translation: {
                    all: 'Tutti',
                    products: 'Prodotti',
                    activities: 'Attività   ',
                    department: 'Reparto',
                    safetyDataSheet: 'Scheda dati di sicurezza (SDS)',
                    productBasedSafetySheet: 'Scheda di sicurezza basata sul prodotto',
                    departmentLocation: 'Ubicazione del reparto',
                    search: 'Cerca',
                    searchForProductsAndActivities: 'Cerca prodotti o attività',
                    activityBasedSafetySheet: 'Scheda di sicurezza basata sulle attività',
                    pageIsNotAvailable: 'Pagina non disponibile',
                    invalidQrCodeOrUrl: 'Il codice QR o il link URL non è valido. Riprovare o contattare l\'amministratore IT per assistenza.',
                    noResults: 'Nessun risultato trovato',
                    tryAdjustingSearch: 'Prova a modificare la ricerca per trovare quello che stai cercando.',
                    welcomeMessage: "Benvenuto in iChemistry Open Access!",
                    aboutApp: "Con Open Access avrai accesso immediato alle informazioni sui prodotti chimici, in modo da poterli gestire in sicurezza.",
                    searchDesc: "Con la funzione di ricerca trova direttamente i prodotti chimici e le relative istruzioni per l'uso.",
                    productsDesc: "Consulta le schede di sicurezza dei prodotti chimici.",
                    activitiesDesc: "Accedi alle istruzioni per l'uso dei prodotti chimici che sono state valutate in relazione a potenziali rischi.",
                    contactMessage: "Per qualsiasi domanda, inviare un'e-mail all'indirizzo",
                    email: "e-mail",
                    continue: "Continua",
                    at: "presso",
                    missingSDS: 'SDS non trovata',
                    infoSDS: 'La SDS richiesta non esiste. Riprova o contatta l\'amministratore IT per ricevere assistenza.',
                    seeAllProducts: "Vedi tutti i prodotti",
                }
            },
            //Latvian
            lv: {
                translation: {
                    all: 'Visi',
                    products: 'Produkti',
                    activities: 'Aktivitātes',
                    department: 'Nodaļa',
                    safetyDataSheet: 'Drošības datu lapa (DDL)',
                    productBasedSafetySheet: 'Produkta drošības lapa',
                    departmentLocation: 'Nodaļas atrašanās vieta',
                    search: 'Meklēšana',
                    searchForProductsAndActivities: 'Meklēt produktus vai aktivitātes',
                    activityBasedSafetySheet: 'Aktivitātes drošības lapa',
                    pageIsNotAvailable: 'Šī lapa nav pieejama',
                    invalidQrCodeOrUrl: 'Kvadrātkods vai URL saite nav derīga. Mēģiniet vēlreiz vai sazinieties ar IT administratoru, lai saņemtu palīdzību.',
                    noResults: 'Nekas nav atrasts',
                    tryAdjustingSearch: 'Lai atrastu vajadzīgo informāciju, mēģiniet pielāgot meklēšanu.',
                    welcomeMessage: "Laipni lūdzam iChemistry Open Access!",
                    aboutApp: "Open Access sniedz iespēju nekavējoties piekļūt informācijai par ķīmisko vielu produktiem, lai tos varētu droši apstrādāt.",
                    searchDesc: "Izmantojiet meklēšanas funkciju, lai atrastu vajadzīgās ķīmiskās vielas un atbilstošos apstrādes norādījumus.",
                    productsDesc: "Piekļūstiet ķīmisko vielu drošības datu lapām.",
                    activitiesDesc: "Skatiet apstrādes norādījumus ķīmiskajām vielām, kas novērtētas attiecībā uz iespējamiem riskiem.",
                    contactMessage: "Ja jums ir jautājumi, sazinieties ar mums, rakstot uz e-pasta adresi",
                    email: "e-pasta adrese",
                    continue: "Turpināt",
                    at: "pie",
                    missingSDS: 'Drošības datu lapa (DDL) nav atrasta',
                    infoSDS: 'Pieprasītā drošības datu lapa (DDL) neeksistē. Mēģiniet vēlreiz vai sazinieties ar IT administratoru, lai saņemtu palīdzību.',
                    seeAllProducts: "Skatīt visus produktus",
                }
            },
            //Lithuanian
            lt: {
                translation: {
                    all: 'Visi',
                    products: 'Produktai',
                    activities: 'Veiklos',
                    department: 'Skyrius',
                    safetyDataSheet: 'Saugos duomenų lapas (SDS)',
                    productBasedSafetySheet: 'Gaminio saugos lapas',
                    departmentLocation: 'Skyriaus informacija',
                    search: 'Ieškoti',
                    searchForProductsAndActivities: 'Ieškokite produktų ar veiklų',
                    activityBasedSafetySheet: 'Veiklos saugos lapas',
                    pageIsNotAvailable: 'Šis puslapis nepasiekiamas',
                    invalidQrCodeOrUrl: 'QR kodas arba URL nuoroda yra netinkami. Bandykite dar kartą arba susisiekite su IT administratoriumi dėl pagalbos.',
                    noResults: 'Rezultatų rasti nepavyko',
                    tryAdjustingSearch: 'Pabandykite pakoreguoti paiešką, kad rastumėte, ko ieškote.',
                    welcomeMessage: "Sveiki prisijungę prie „iChemistry“ atvirosios prieigos!",
                    aboutApp: "Naudodamiesi atvirąja prieiga gausite tiesioginę prieigą prie informacijos apie savo cheminius produktus, kad galėtumėte saugiai juos tvarkyti.",
                    searchDesc: "Naudodamiesi paieškos funkcija tiesiogiai raskite savo chemines medžiagas ir atitinkamas naudojimo instrukcijas.",
                    productsDesc: "Peržiūrėkite savo cheminių medžiagų saugos duomenų lapus.",
                    activitiesDesc: "Susipažinkite su savo cheminių medžiagų, kurios buvo įvertintos dėl galimų pavojų, tvarkymo instrukcijomis.",
                    contactMessage: "Jei turite klausimų, rašykite el. pašto adresu",
                    email: "el. paštas",
                    continue: "Tęsti",
                    at: "adresu",
                    missingSDS: 'SDL nerastas',
                    infoSDS: 'Prašomo SDL nėra. Bandykite dar kartą arba susisiekite su IT administratoriumi dėl pagalbos.',
                    seeAllProducts: "Peržiūrėti visus produktus",
                }
            },
            //Polish
            pl: {
                translation: {
                    all: 'Wszyscy',
                    products: 'Produkty',
                    activities: 'Działania',
                    department: 'Dział',
                    safetyDataSheet: 'Karta charakterystyki substancji (SDS)',
                    productBasedSafetySheet: 'Karta charakterystyki oparta na produkcie',
                    departmentLocation: 'Lokalizacja działu',
                    search: 'Szukaj',
                    searchForProductsAndActivities: 'Wyszukaj produkty lub działania',
                    activityBasedSafetySheet: 'Karta charakterystyki oparta na działaniu',
                    pageIsNotAvailable: 'Ova stranica nije dostupna',
                    invalidQrCodeOrUrl: 'QR kôd ili URL link nisu važeći. Pokušajte ponovo ili zatražite pomoć od IT administratora.',
                    noResults: 'Nema pronađenih rezultata',
                    tryAdjustingSearch: 'Prilagodite pretragu da biste pronašli to što tražite.',
                    welcomeMessage: "Witamy w otwartych zasobach iChemistry Open Access!",
                    aboutApp: "Dzięki Open Access uzyskujesz natychmiastowy dostęp do informacji o swoich produktach chemicznych, dzięki czemu możesz bezpiecznie się z nimi obchodzić.",
                    searchDesc: "Bezpośrednie wyszukiwanie produktów chemicznych oraz odpowiednich instrukcji obsługi za pomocą funkcji wyszukiwania.",
                    productsDesc: "Dostęp do kart charakterystyki substancji chemicznych.",
                    activitiesDesc: "Dostęp do instrukcji postępowania z produktami chemicznymi, które zostały ocenione pod kątem potencjalnych zagrożeń.",
                    contactMessage: "W razie pytań prosimy o kontakt pod adresem",
                    email: "e-mail",
                    continue: "Kontynuuj",
                    at: "u",
                    missingSDS: 'Nie znaleziono SDS',
                    infoSDS: 'Szukana karta SDS nie istnieje. Spróbuj ponownie lub skontaktuj się z administratorem IT, aby uzyskać pomoc.',
                    seeAllProducts: "Zobacz wszystkie produkty",
                }
            },
            //Portugese
            pt: {
                translation: {
                    all: 'Todos',
                    products: 'Produtos',
                    activities: 'Atividades',
                    department: 'Departamento',
                    safetyDataSheet: 'Ficha de dados de segurança (FDS)',
                    productBasedSafetySheet: 'Ficha de segurança relativa ao produto',
                    departmentLocation: 'Localização do departamento',
                    search: 'Pesquisar',
                    searchForProductsAndActivities: 'Pesquisar produtos ou atividades',
                    activityBasedSafetySheet: 'Ficha de segurança relativa à atividade',
                    pageIsNotAvailable: 'Esta página não está disponível',
                    invalidQrCodeOrUrl: 'O código QR ou o link URL são inválidos. Tente de novo ou contacte o seu administrador de TI para obter assistência.',
                    noResults: 'Nenhum resultado encontrado',
                    tryAdjustingSearch: 'Tente ajustar a sua pesquisa para encontrar o que procura.',
                    welcomeMessage: "Bem-vindo ao Acesso Livre do iChemistry!",
                    aboutApp: "Com o Acesso Livre, tem acesso imediato à informação sobre os seus produtos químicos, para os poder manusear em segurança.",
                    searchDesc: "Encontre os seus produtos químicos e as respetivas instruções de manuseamento, diretamente, através da função de pesquisa.",
                    productsDesc: "Aceda às Fichas de Dados de Segurança dos seus produtos químicos.",
                    activitiesDesc: "Aceda às instruções de manuseamento dos seus produtos químicos que foram avaliados quanto a riscos potenciais.",
                    contactMessage: "Em caso de dúvidas, contacte",
                    email: "e-mail",
                    continue: "Continuar",
                    at: "em",
                    missingSDS: 'FDS não encontrada',
                    infoSDS: 'A FDS solicitada não existe. Tente de novo ou contacte o seu administrador de TI para obter assistência.',
                    seeAllProducts: "Ver todos os produtos",
                }
            },
            //Portugese-Brasil
            'pt-br': {
                translation: {
                    all: 'Todos',
                    products: 'Produtos',
                    activities: 'Atividades',
                    department: 'Setor',
                    safetyDataSheet: 'Folha de dados de segurança (FDS)',
                    productBasedSafetySheet: 'Folha de segurança baseada em produtos',
                    departmentLocation: 'Localização do setor',
                    search: 'Pesquisar',
                    searchForProductsAndActivities: 'Pesquisar produtos ou atividades',
                    activityBasedSafetySheet: 'Folha de segurança baseada em atividades',
                    pageIsNotAvailable: 'Esta página está indisponível',
                    invalidQrCodeOrUrl: 'O código QR ou link URL é inválido. Tente novamente ou entre em contato com o administrador de TI para obter assistência.',
                    noResults: 'Nenhum resultado encontrado',
                    tryAdjustingSearch: 'Tente ajustar sua pesquisa para encontrar o que está procurando.',
                    welcomeMessage: "Bem-vindo ao iChemistry com Open Access!",
                    aboutApp: "Com o Open Access (Acesso aberto), você obtém acesso imediato a informações sobre seus produtos químicos, para poder manuseá-los com segurança.",
                    searchDesc: "Encontre seus produtos químicos e as instruções de manuseio correspondentes de forma direta, utilizando a função de pesquisa.",
                    productsDesc: "Acesse as Folhas de Dados de Segurança de seus produtos químicos.",
                    activitiesDesc: "Acesse as instruções de manuseio de seus produtos químicos que foram avaliados quanto a riscos potenciais.",
                    contactMessage: "Em caso de dúvidas, entre em contato com",
                    email: "e-mail",
                    continue: "Continuar",
                    at: "em",
                    missingSDS: 'FDS não encontrada',
                    infoSDS: 'A FDS solicitada não existe. Tente novamente ou entre em contato com seu administrador de TI para obter assistência.',
                    seeAllProducts: "Ver todos os produtos",
                }
            },
            //Romanian
            ro: {
                translation: {
                    all: 'Toți',
                    products: 'Produse',
                    activities: 'Activități',
                    department: 'Departament',
                    safetyDataSheet: 'Fișă tehnică de securitate (FTS)',
                    productBasedSafetySheet: 'Fișă de securitate pentru produs',
                    departmentLocation: 'Locație departament',
                    search: 'Căutare',
                    searchForProductsAndActivities: 'Căutați produse sau activități',
                    activityBasedSafetySheet: 'Fișă de securitate pentru activitate',
                    pageIsNotAvailable: 'Această pagină nu este disponibilă',
                    invalidQrCodeOrUrl: 'Codul QR sau linkul URL nu este valid. Încercați din nou sau contactați administratorul IT pentru asistență.',
                    noResults: 'Niciun rezultat găsit',
                    tryAdjustingSearch: 'Încercați să ajustați căutarea pentru a găsi ceea ce căutați.',
                    welcomeMessage: "Bun venit la iChemistry Open Access!",
                    aboutApp: "Cu Open Access, aveți acces imediat la informații despre produsele dvs. chimice, astfel încât să le puteți gestiona în siguranță.",
                    searchDesc: "Găsiți substanțele chimice și instrucțiunile de manipulare corespunzătoare direct, cu ajutorul funcției de căutare.",
                    productsDesc: "Accesați fișele tehnice de securitate pentru substanțele chimice.",
                    activitiesDesc: "Accesați instrucțiunile de manipulare pentru substanțele chimice care au fost evaluate pentru a se stabili dacă prezintă posibile riscuri.",
                    contactMessage: "Dacă aveți întrebări, contactați",
                    email: "e-mail",
                    continue: "Continuare",
                    at: "la",
                    missingSDS: 'FTS negăsită',
                    infoSDS: 'FTS solicitată nu există. Încercați din nou sau contactați administratorul IT pentru asistență.',
                    seeAllProducts: "Vezi toate produsele",
                }
            },
            //Russian
            ru: {
                translation: {
                    all: 'Все',
                    products: 'Продукты',
                    activities: 'Виды деятельности',
                    department: 'Подразделение',
                    safetyDataSheet: 'Паспорт безопасности (SDS)',
                    productBasedSafetySheet: 'Паспорт безопасности на основе продукта',
                    departmentLocation: 'Местоположение подразделения',
                    search: 'Поиск',
                    searchForProductsAndActivities: 'Поиск продуктов или видов деятельности',
                    activityBasedSafetySheet: 'Паспорт безопасности на основе вида деятельности',
                    pageIsNotAvailable: 'Эта страница недоступна',
                    invalidQrCodeOrUrl: 'Недействительный QR-код или URL-адрес. Повторите попытку или обратитесь за помощью к ИТ-администратору.',
                    noResults: 'Результаты не найдены',
                    tryAdjustingSearch: 'Попробуйте изменить критерии поиска.',
                    welcomeMessage: "Добро пожаловать в iChemistry Open Access!",
                    aboutApp: "Благодаря Open Access вы получаете немедленный доступ к информации о ваших химических продуктах, что позволяет безопасно их использовать.",
                    searchDesc: "Воспользуйтесь функцией поиска, чтобы найти химические вещества и соответствующие инструкции по обращению с ними.",
                    productsDesc: "Ознакомьтесь с паспортами безопасности ваших химических веществ.",
                    activitiesDesc: "Ознакомьтесь с инструкциями по обращению с химическими веществами, которые прошли оценку на предмет потенциальных рисков.",
                    contactMessage: "При возникновении любых вопросов обращайтесь по адресу",
                    email: "эл. почта",
                    continue: "Продолжить",
                    at: "у",
                    missingSDS: 'SDS не найден',
                    infoSDS: 'Запрошенный SDS не существует. Повторите попытку или обратитесь за помощью к ИТ-администратору.',
                    seeAllProducts: "Просмотреть все продукты",
                }
            },
            //Serbo-Croatian
            sh: {
                translation: {
                    all: 'Svi',
                    products: 'Proizvodi',
                    activities: 'Aktivnosti',
                    department: 'Odeljenje',
                    safetyDataSheet: 'Bezbednosni list',
                    productBasedSafetySheet: 'Bezbednosni list zasnovan na proizvodu',
                    departmentLocation: 'Lokacija odeljenja',
                    search: 'Pretraga',
                    searchForProductsAndActivities: 'Potraži proizvode ili aktivnosti',
                    activityBasedSafetySheet: 'Bezbednosni list zasnovan na aktivnosti',
                    pageIsNotAvailable: 'Ova stranica nije dostupna',
                    invalidQrCodeOrUrl: 'QR kôd ili URL link nisu važeći. Pokušajte ponovo ili zatražite pomoć od IT administratora.',
                    noResults: 'Nema pronađenih rezultata',
                    tryAdjustingSearch: 'Prilagodite pretragu da biste pronašli to što tražite.',
                    welcomeMessage: "Dobro došli u iChemistry Open Access!",
                    aboutApp: "Uz Open Access dobijate neposredni pristup informacijama o hemijskim proizvodima kako biste mogli njima bezbedno da rukujete.",
                    searchDesc: "Direktno pronađite hemikalije i odgovarajuća uputstva za rukovanje pomoću funkcije pretrage.",
                    productsDesc: "Pristupite bezbednosnim listovima za hemikalije.",
                    activitiesDesc: "Pristupite uputstvima za rukovanje za hemikalije koje su procenjene u pogledu potencijalnih opasnosti.",
                    contactMessage: "Ukoliko imate bilo kakvih pitanja, obratite se na adresu",
                    email: "adresa e-pošte",
                    continue: "Nastavite",
                    at: "za",
                    missingSDS: 'Bezbednosni list nije pronađen',
                    infoSDS: 'Traženi bezbednosni list ne postoji. Pokušajte ponovo ili zatražite pomoć od internet administratora.',
                    seeAllProducts: "Pogledaj sve proizvode",
                }
            },
            //Serbian
            sr: {
                translation: {
                    all: 'Сви',
                    products: 'Производи',
                    activities: 'Активности',
                    department: 'Одељење',
                    safetyDataSheet: 'Безбедносни лист',
                    productBasedSafetySheet: 'Безбедносни лист заснован на производу',
                    departmentLocation: 'Локација одељења',
                    search: 'Претрага',
                    searchForProductsAndActivities: 'Потражите производе или активности',
                    activityBasedSafetySheet: 'Безбедносни лист заснован на активности',
                    pageIsNotAvailable: 'Ова страница није доступна',
                    invalidQrCodeOrUrl: 'QR кôд или URL линк нису важећи. Покушајте поново или затражите помоћ од IT администратора.',
                    noResults: 'Нема пронађених резултата',
                    tryAdjustingSearch: 'Прилагодите претрагу да бисте пронашли то што тражите.',
                    welcomeMessage: "Добро дошли у iChemistry Open Access!",
                    aboutApp: "Уз Open Access добијате непосредни приступ информацијама о хемијским производима како бисте могли њима безбедно да рукујете.",
                    searchDesc: "Директно пронађите хемикалије и одговарајућа упутства за руковање помоћу функције претраге.",
                    productsDesc: "Приступите безбедносним листовима за хемикалије.",
                    activitiesDesc: "Приступите упутствима за руковање за хемикалије које су процењене у погледу потенцијалних опасности.",
                    contactMessage: "Уколико имате било каквих питања, обратите се на адресу",
                    email: "адресу е-поште",
                    continue: "Наставите",
                    at: "за",
                    missingSDS: 'Безбедносни лист није пронађен',
                    infoSDS: 'Тражени безбедносни лист не постоји. Покушајте поново или затражите помоћ од интернет администратора.',
                    seeAllProducts: "Погледај све производе",
                }
            },
            //Slovak
            sk: {
                translation: {
                    all: 'Všetci',
                    products: 'Produkty',
                    activities: 'Činnosti',
                    department: 'Oddelenie',
                    safetyDataSheet: 'Karta bezpečnostných údajov (KBÚ)',
                    productBasedSafetySheet: 'Karta bezpečnostných údajov podľa produktu',
                    departmentLocation: 'Sídlo oddelenia',
                    search: 'Vyhľadať',
                    searchForProductsAndActivities: 'Vyhľadať produkty alebo činnosti',
                    activityBasedSafetySheet: 'Karta bezpečnostných údajov podľa činnosti',
                    pageIsNotAvailable: 'Stránka nie je k dispozícii',
                    invalidQrCodeOrUrl: 'Kód QR alebo odkaz URL nie je platný. Skúste to znova alebo sa obráťte na svojho IT správcu.',
                    noResults: 'Nenašli sa žiadne výsledky',
                    tryAdjustingSearch: 'Upravte svoje vyhľadávanie, aby ste našli, čo hľadáte.',
                    welcomeMessage: "Vitajte v aplikácii Open Access systému iChemistry!",
                    aboutApp: "S aplikáciou Open Access získate okamžitý prístup k informáciám o svojich chemických produktoch, aby ste s nimi mohli bezpečne zaobchádzať.",
                    searchDesc: "Nájdite svoje chemikálie a príslušné pokyny na zaobchádzanie priamo pomocou funkcie vyhľadávania.",
                    productsDesc: "Získajte prístup ku kartám bezpečnostných údajov svojich chemikálií.",
                    activitiesDesc: "Získajte prístup k pokynom na zaobchádzanie s chemikáliami, ktoré boli vyhodnotené z hľadiska potenciálnych rizík.",
                    contactMessage: "V prípade akýchkoľvek otázok nám napíšte na adresu",
                    email: "e-mail",
                    continue: "Pokračovať",
                    at: "u zákazníka",
                    missingSDS: 'KBÚ sa nenašla',
                    infoSDS: 'Požadovaná KBÚ neexistuje. Skúste to znova alebo sa obráťte na svojho IT správcu.',
                    seeAllProducts: "Zobraziť všetky produkty",
                }
            },
            //Slovenian
            sl: {
                translation: {
                    all: 'Vsi',
                    products: 'Izdelki',
                    activities: 'Dejavnosti',
                    department: 'Oddelek',
                    safetyDataSheet: 'Varnostni list',
                    productBasedSafetySheet: 'Varnostni list glede na izdelek',
                    departmentLocation: 'Lokacija oddelka',
                    search: 'Iskanje',
                    searchForProductsAndActivities: 'Iskanje izdelkov ali dejavnosti',
                    activityBasedSafetySheet: 'Varnostni list glede na dejavnost',
                    pageIsNotAvailable: 'Ta stran ni na voljo',
                    invalidQrCodeOrUrl: 'Koda QR ali povezava URL ni veljavna. Poskusite znova ali se obrnite na svojega skrbnika za IT.',
                    noResults: 'Ni rezultatov iskanja',
                    tryAdjustingSearch: 'Poskusite prilagoditi iskanje, da bi našli tisto, kar iščete.',
                    welcomeMessage: "Dobrodošli v iChemistry odprt dostop!",
                    aboutApp: "Z odprtim dostopom dobite takojšen dostop do informacij o svojih kemičnih izdelkih, kar vam omogoča varno ravnanje z njimi.",
                    searchDesc: "S funkcijo iskanje neposredno poiščite svoje kemikalije in ustrezna navodila za ravnanje.",
                    productsDesc: "Pridobite dostop do varnostnih listov za svoje kemikalije.",
                    activitiesDesc: "Pridobite dostop do navodil za ravnanje s kemikalijami, ki so bile ocenjene glede na morebitna tveganja.",
                    contactMessage: "Če imate kakršna koli vprašanja, se obrnite na",
                    email: "E-pošta",
                    continue: "Naprej",
                    at: "pri",
                    missingSDS: 'SDS ni najden',
                    infoSDS: 'Zahtevani SDS ne obstaja. Poskusite znova ali se obrnite na svojega skrbnika za IT.',
                    seeAllProducts: "Poglej vseh izdelkov",
                }
            },
            //Spanish
            es: {
                translation: {
                    all: 'Todos',
                    products: 'Productos',
                    activities: 'Actividades',
                    department: 'Departamento',
                    safetyDataSheet: 'Ficha de datos de seguridad (FDS)',
                    productBasedSafetySheet: 'Ficha de seguridad basada en el producto',
                    departmentLocation: 'Ubicación del departamento',
                    search: 'Buscar',
                    searchForProductsAndActivities: 'Buscar productos o actividades',
                    activityBasedSafetySheet: 'Ficha de seguridad basada en la actividad',
                    pageIsNotAvailable: 'Esta página no está disponible',
                    invalidQrCodeOrUrl: 'El código QR o el enlace URL no son válidos. Inténtelo de nuevo o contacte con su administrador de TI para obtener ayuda.',
                    noResults: 'No se ha encontrado ningún resultado',
                    tryAdjustingSearch: 'Intente ajustar su búsqueda para encontrar lo que está buscando.',
                    welcomeMessage: "Bienvenido a iChemistry Open Access.",
                    aboutApp: "Con Open Access obtendrá acceso inmediato a información sobre sus productos químicos para que pueda manipularlos de forma segura.",
                    searchDesc: "Encuentre directamente sus productos químicos y las instrucciones de manipulación correspondientes utilizando la función de búsqueda.",
                    productsDesc: "Acceda a las fichas de datos de seguridad de sus productos químicos.",
                    activitiesDesc: "Acceda a las instrucciones de manipulación de sus productos químicos que se han evaluado para detectar posibles riesgos.",
                    contactMessage: "Si tiene alguna pregunta, póngase en contacto con",
                    email: "correo electrónico",
                    continue: "Continuar",
                    at: "en",
                    missingSDS: 'FDS no encontrada',
                    infoSDS: 'La FDS solicitada no existe. Inténtelo de nuevo o contacte con su administrador de TI para obtener ayuda.',
                    seeAllProducts: "Ver todos los productos",
                }
            },
            //Spanish-Mexico
            'es-mx': {
                translation: {
                    all: 'Todos',
                    products: 'Productos',
                    activities: 'Actividades',
                    department: 'Departamento',
                    safetyDataSheet: 'Ficha de datos de seguridad (SDS)',
                    productBasedSafetySheet: 'Ficha de seguridad basada en productos',
                    departmentLocation: 'Ubicación del departamento',
                    search: 'Buscar',
                    searchForProductsAndActivities: 'Buscar productos o actividades',
                    activityBasedSafetySheet: 'Ficha de seguridad basada en actividades',
                    pageIsNotAvailable: 'Esta página no está disponible',
                    invalidQrCodeOrUrl: 'El código QR o el enlace URL no son válidos. Inténtelo de nuevo o solicite ayuda al administrador de TI.',
                    noResults: 'No se encontraron resultados',
                    tryAdjustingSearch: 'Intente ajustar su búsqueda para encontrar lo que necesita.',
                    welcomeMessage: "Bienvenido a iChemistry Open Access.",
                    aboutApp: "Con Open Access obtendrá acceso inmediato a información sobre sus productos químicos, para que pueda manejarlos de forma segura.",
                    searchDesc: "Encuentre directamente sus productos químicos y las instrucciones de manipulación correspondientes utilizando la función de búsqueda.",
                    productsDesc: "Acceda a las fichas de datos de seguridad de sus productos químicos.",
                    activitiesDesc: "Acceda a las instrucciones de manipulación de sus productos químicos que se han evaluado para detectar posibles riesgos.",
                    contactMessage: "Si tiene alguna pregunta, póngase en contacto con",
                    email: "correo electrónico",
                    continue: "Continuar",
                    at: "en",
                    missingSDS: 'FDS no encontrada',
                    infoSDS: 'La FDS solicitada no existe. Inténtelo de nuevo o solicite ayuda al administrador de TI.',
                    seeAllProducts: "Ver todos los productos",
                }
            },
            //Turkish
            tr: {
                translation: {
                    all: 'Tüm',
                    products: 'Ürünler',
                    activities: 'Faaliyetler',
                    department: 'Departman',
                    safetyDataSheet: 'Güvenlik Veri Formu (SDS)',
                    productBasedSafetySheet: 'Ürün Temelli Güvenlik Formu',
                    departmentLocation: 'Departman konumu',
                    search: 'Ara',
                    searchForProductsAndActivities: 'Ürün veya faaliyet ara',
                    activityBasedSafetySheet: 'Faaliyet Temelli Güvenlik Formu',
                    pageIsNotAvailable: 'Bu sayfa mevcut değil',
                    invalidQrCodeOrUrl: 'QR Kodu veya URL bağlantısı geçersiz. Lütfen tekrar deneyin veya yardım için BT yöneticinizle iletişime geçin.',
                    noResults: 'Sonuç bulunamadı',
                    tryAdjustingSearch: 'Aradığınızı bulmak için aramanızı düzenlemeyi deneyin.',
                    welcomeMessage: "iChemistry Open Access'e Hoş Geldiniz!",
                    aboutApp: "Open Access ile kimyasal ürünlerinizle ilgili bilgilere anında erişerek bu ürünleri güvenli şekilde kullanabilirsiniz.",
                    searchDesc: "Arama fonksiyonunu kullanarak kimyasal maddelerinizi ve ilgili kullanım talimatlarını doğrudan bulabilirsiniz.",
                    productsDesc: "Kimyasallarınız için Güvenlik Bilgi Formlarına erişin.",
                    activitiesDesc: "Potansiyel riskler için değerlendirilen kimyasallarınızın kullanım talimatlarına erişin.",
                    contactMessage: "Herhangi bir sorunuz olursa, lütfen iletişime geçiniz:",
                    email: "e-posta",
                    continue: "Devam edin",
                    at: "adlı müşteride",
                    missingSDS: 'SDS bulunamadı',
                    infoSDS: 'İstenen SDS mevcut değil. Lütfen tekrar deneyin veya yardım için BT yöneticinizle iletişime geçin.',
                    seeAllProducts: "Tüm ürünleri gör",
                }
            }
        }
    });

export default function (app) {
    app.use(I18NextVue, { i18next });
    return app;
}