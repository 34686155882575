import i18next from "i18next";
import { reactive, toRefs } from "vue";

const state = reactive({
    currentLanguage: {
        products: null,
        activities: null,
        wpi: null,
        department: null,
        safetyDataSheet: null,
        productBasedSafetySheet: null,
        wpiFile: null,
        departmentLocation: null,
        search: null,
        searchForProductsAndActivities: null,
        searchForProductsActivitiesOrWPIs: null,
        activityBasedSafetySheet: null,
        welcomeMessage: null,
        aboutApp: null,
        searchDesc: null,
        productsDesc: null,
        activitiesDesc: null,
        contactMessage: null,
        email: null,
        continue: null,
        pageIsNotAvailable: null,
        invalidQrCodeOrUrl: null,
        noResults: null,
        tryAdjustingSearch: null,
        missingSDS: null,
        infoSDS: null
    }
});


const getLanguageIsoCode = (language) => {
    language = language.toLowerCase();

    // Exceptions where we take the whole code
    if (["pt-br", "es-mx"].includes(language)) {
        return language;
    }
    //Get the first two letters of code (639 2-letter language code)
    let languageCode = language.split("-").at(0);

    // All versions of Norwegian language are mapped to 'no'
    if (["nn", "nb"].includes(languageCode)) {
        return 'no';
    }

    return languageCode;
};

const traslatePhrasesToIi8Language = () => {
    state.currentLanguage.all = i18next.t("all");
    state.currentLanguage.products = i18next.t("products");
    state.currentLanguage.activities = i18next.t("activities");
    state.currentLanguage.wpi = i18next.t("wpi");
    state.currentLanguage.department = i18next.t("department");
    state.currentLanguage.safetyDataSheet = i18next.t("safetyDataSheet");
    state.currentLanguage.productBasedSafetySheet = i18next.t("productBasedSafetySheet");
    state.currentLanguage.wpiFile = i18next.t("wpiFile");
    state.currentLanguage.departmentLocation = i18next.t("departmentLocation");
    state.currentLanguage.search = i18next.t("search");
    state.currentLanguage.searchForProductsAndActivities = i18next.t("searchForProductsAndActivities");
    state.currentLanguage.searchForProductsActivitiesOrWPIs = i18next.t("searchForProductsActivitiesOrWPIs");
    state.currentLanguage.activityBasedSafetySheet = i18next.t("activityBasedSafetySheet");
    state.currentLanguage.welcomeMessage = i18next.t("welcomeMessage");
    state.currentLanguage.aboutApp = i18next.t("aboutApp");
    state.currentLanguage.searchDesc = i18next.t("searchDesc");
    state.currentLanguage.activitiesDesc = i18next.t("activitiesDesc");
    state.currentLanguage.productsDesc = i18next.t("productsDesc");
    state.currentLanguage.contactMessage = i18next.t("contactMessage");
    state.currentLanguage.email = i18next.t("email");
    state.currentLanguage.continue = i18next.t("continue");
    state.currentLanguage.pageIsNotAvailable = i18next.t("pageIsNotAvailable");
    state.currentLanguage.invalidQrCodeOrUrl = i18next.t("invalidQrCodeOrUrl");
    state.currentLanguage.noResults = i18next.t("noResults");
    state.currentLanguage.tryAdjustingSearch = i18next.t("tryAdjustingSearch");
    state.currentLanguage.missingSDS = i18next.t("missingSDS");
    state.currentLanguage.infoSDS = i18next.t("infoSDS");
    state.currentLanguage.seeAllProducts = i18next.t("seeAllProducts");
}


export default function useTranslations() {

    const changeLanguage = (language) => {

        let languageCode = getLanguageIsoCode(language);

        i18next.changeLanguage(languageCode);

        traslatePhrasesToIi8Language();
    }

    return { ...toRefs(state), changeLanguage };
}